// extracted by mini-css-extract-plugin
export var container = "PrrHHqWT";
export var rectangle = "bSF-RZIU";
export var bashHeader = "nWCHFnXh";
export var bashControls = "mDmWlRfJ";
export var bashButton = "NwR5vwMY";
export var bashButton__red = "NIpTNQLJ";
export var bashButton__yellow = "A-nbsszz";
export var bashButton__green = "_3iW-RZC5";
export var title = "T8cs2Y+G";
export var body = "ublK3fdL";
export var text = "uwiA2geE";
export var atmosphere = "HETG0HGy";
export var wave = "B0VQuaDR";