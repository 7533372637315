// extracted by mini-css-extract-plugin
export var header = "_9rxeYi3X";
export var heading = "Hxb4rdBZ";
export var frame = "wkhwhNIu";
export var items = "_3T6iKBl9";
export var items__hide = "EvLYRT2e";
export var button = "UCiiA5rJ";
export var pgq = "QVxxvyul";
export var icon = "bN6uFsvZ";
export var content = "IgMTaaMW";
export var content__hide = "R-XAq02V";
export var content_header = "_7v1ulsTP";
export var content_text = "e+-GHK4R";
export var question_body = "EHWHyAp0";
export var btcc_title = "T5DJCkiF";
export var btcc_icons = "jx5vt7q5";