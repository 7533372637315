// extracted by mini-css-extract-plugin
export var grid = "_5No5NH+R";
export var item = "syLregTO";
export var content = "wm2VmdiO";
export var content__hidden = "XH+3OTHT";
export var container = "oVtdAOPj";
export var frame = "_5o6KFODF";
export var cover = "xIf9hpMJ";
export var question = "PJfQNq5b";
export var question__outline = "dVcJhr1Y";
export var question__solid = "IlMTCJZk";
export var angle = "_5XebTxC-";