// extracted by mini-css-extract-plugin
export var container = "qSA9ORXf";
export var linesContainer = "nG6CGnqR";
export var infoContainer = "r5gYTK0B";
export var header = "_53PMHtTF";
export var gt = "Sa5ZGx9r";
export var title = "ljFHSGmK";
export var version = "WuQcZgD+";
export var subheader = "_2h+oJGS4";
export var prompt = "rT7oFs3b";
export var registerButton = "NCmMUE7N";
export var registerButton__disabled = "D+zR1QkY";
export var imageContainer = "SPpPt9jY";